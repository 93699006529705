import * as React from "react";
import Header from "../components/Header/Header";
import "./../sass/main.scss";
import Footer from "../components/Footer/Footer";
import { CircularProgress } from "@mui/material";

// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination, A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";

// Images
import lowesLady from "../images/lowes-lady.png";
import lowesGuy from "../images/lowes-guy.png";
import lowesEmployee from "../images/lowes-employee.png";
import bigMarker from "../images/jobs-near-you.svg";
import bigMarkerFR from "../images/jobs-near-you-fr.svg";
// import bigMarker from "../images/careers-heading.svg"
// import bigMarkerFR from "../images/careers-heading-fr-1.svg"
import banners from "../images/Rona logo white.svg";
import employee from "../images/employee.png";
import ccdiLogo from "../images/20151013 - CCDI - Logo - Employer Partner.png";
import ccdiLogoFr from "../images/20151013 - CCDI - Logo - Employer Partner-fr.png";
import call2Recycle from "../images/Call2Recycle.jpeg";
import call2RecycleFr from "../images/Call2Recycle-fr.jpg";
import energyStar from "../images/Energy Star_EN.jpeg";
import energyStarFr from "../images/Energy Star_fr.jpg";
import greenest2021 from "../images/greenest2021-english.png";
import greenest2021Fr from "../images/greenest2021-fr.png";
import mtlTopEmployer from "../images/Logo Mtl top employer FR.png";
import mtlTopEmployerFr from "../images/Logo Mtl top employer-fr.png";
import era2019 from "../images/ERA 2019 carr_EN.png";
import era2019Fr from "../images/ERA 2019 carr_fr.png";
import hiringEvent from "../images/hero-en-2-2024.jpg";
import hiringEventFr from "../images/hero-fr-2-2024.jpg";

SwiperCore.use([Autoplay, Pagination, Navigation]);
let EN_URL = process.env.EN_URL ? process.env.EN_URL : "/";
let FR_URL = process.env.FR_URL ? process.env.FR_URL : "/";
const IndexPage = () => {
  let isFrench = process.env.LANG === "FR";
  let isInternal = process.env.INT_EXT === "internal";

  let p1;
  let p2;
  let p3;

  if (isFrench) {
    if (isInternal) {
      p1 =
        "Nous sommes en train d'améliorer notre site carrière. Il nous est présentement impossible d’accepter votre candidature.";
      p2 =
        "À compter du 25 novembre, vous aurez accès à toutes les offres internes directement dans Workday. Nous vous invitons à consulter nos opportunités à ce moment-là.";
      p3 = "Merci de vouloir grandir au sein de la famille RONA !";
    } else {
      p1 =
        "Nous sommes en train d'améliorer notre site carrière. Il nous est présentement impossible d’accepter votre candidature. Veuillez revenir le 25 novembre.";
      p3 = "Merci de votre intérêt à joindre la famille RONA !";
    }
  } else {
    if (isInternal) {
      p1 =
        "We're in the process of improving our career site. We’re unable to accept your application for the moment.";
      p2 =
        "As of November 25, you will have access to all internal postings directly in Workday. We invite you to browse our opportunities at that moment.";
      p3 = "Thank you for wanting to grow within the RONA family!";
    } else {
      p1 =
        "We're in the process of improving our career site. We’re unable to accept your application for the moment. Please come back on November 25.";
      p3 = "Thank you for your interest in joining the RONA family!";
    }
  }

  return (
    <>
      <Header
        title={
          isFrench
            ? "Carrières chez RONA inc., et Rona"
            : "Careers at RONA inc., and Rona"
        }
        description={
          isFrench
            ? "Vous cherchez à entamer un nouveau chapitre avec RONA inc.? Consultez nos offres d'emploi ici et commencez votre carrière chez RONA inc., et Rona"
            : "Looking to start a new chapter with RONA inc.? Search our openings here and start your career with RONA inc., and Rona"
        }
        hidden={false}
        lang={process.env.LANG ? process.env.LANG : "en"}
        lang_link={isFrench ? `${EN_URL}/` : `${FR_URL}/`}
        bodyClasses={`page-index${isInternal ? " page-index-internal" : ""}`}
      />
      <main id={"main"}>
        <section>
          <div
            className={""}
            style={{
              paddingTop: "96px",
              paddingBottom: "96px",
              paddingLeft: "6px",
              paddingRight: "6px",
              display: "flex",
              gap: "12px",
              flexDirection: "column",
              maxWidth: "716px",
              margin: "auto",
            }}
          >
            <p
              className={"text-center"}
              style={{
                fontSize: "24px",
              }}
            >
              {p1}
            </p>
            <p className={"text-center"} style={{ fontSize: "24px" }}>
              {p2}
            </p>
            <p className={"text-center"} style={{ fontSize: "24px" }}>
              {p3}
            </p>
          </div>
        </section>
      </main>
      <Footer lang={process.env.LANG ? process.env.LANG : "en"} />
    </>
  );
};

export default IndexPage;
